import { lazy } from "react";
import { Configuration } from "../configuration";
import RedirectRoute from "./redirectRoute";

export const EmailLoginRoute = lazy(() => import("../pages/login"));
export const AdminDashboardRoute = lazy(
  () => import("../pages/loggedIn/admin/dashboard")
);
export const LoggedInDashboardRoute = lazy(
  () => import("../pages/loggedIn/user/dashboard")
);
export const AdminReferenceRoute = lazy(
  () => import("../pages/loggedIn/admin/reference")
);
export const AdminReferenceEditRoute = lazy(
  () => import("../pages/loggedIn/admin/reference/editReferral")
);
export const AdminEditProfileViewRoute = lazy(
  () => import("../pages/loggedIn/admin/editProfile")
);
export const UserEditProfileViewRoute = lazy(
  () => import("../pages/loggedIn/user/editProfile")
);
export const UserReferenceRoute = lazy(
  () => import("../pages/loggedIn/user/reference")
);
export const AdminCustomerListRoute = lazy(
  () => import("../pages/loggedIn/admin/customer")
);
export const AdminSettingsRoute = lazy(
  () => import("../pages/loggedIn/admin/settings")
);
export const ChangePasswordRoute = lazy(
  () => import("../pages/login/changePassword")
);
export const ForgotPasswordRoute = lazy(
  () => import("../pages/login/forgotPassword")
);
export const UserSettingsRoute = lazy(
  () => import("../pages/loggedIn/user/settings")
);
export const AdminCustomerViewRoute = lazy(
  () => import("../pages/loggedIn/admin/customer/viewCustomer")
);

export const routeList = [
  {
    path: "/",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.noAuth,
          }}
        >
          <EmailLoginRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/changePassword",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.noAuth,
          }}
        >
          <ChangePasswordRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/forgotPassword",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.noAuth,
          }}
        >
          <ForgotPasswordRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/userDashboard",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.customer,
          }}
        >
          <LoggedInDashboardRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/userEditProfile",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.customer,
          }}
        >
          <UserEditProfileViewRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/userSettings",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.customer,
          }}
        >
          <UserSettingsRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/userReference",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.customer,
          }}
        >
          <UserReferenceRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminDashboard",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.admin,
          }}
        >
          <AdminDashboardRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminCustomer",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.admin,
          }}
        >
          <AdminCustomerListRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminViewCustomer",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.admin,
          }}
        >
          <AdminCustomerViewRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminSettings",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.admin,
          }}
        >
          <AdminSettingsRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminEditProfile",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.admin,
          }}
        >
          <AdminEditProfileViewRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminReference",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.admin,
          }}
        >
          <AdminReferenceRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "/adminEditReference",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.admin,
          }}
        >
          <AdminReferenceEditRoute />
        </RedirectRoute>
      </>
    ),
  },
  {
    path: "*",
    element: (
      <>
        <RedirectRoute
          dataObj={{
            routeType: Configuration.routeTypes.noAuth,
          }}
        >
          <EmailLoginRoute />
        </RedirectRoute>
      </>
    ),
  },
];
